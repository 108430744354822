<template>
  <BtnDropShadow
    class="btn--credit">
    <!-- <SvgPlus class="btn--add__icon" /> -->
    <img
      class="img--credit"
      :style="{ width: (text >= 1000 && wXS) ? '25px': '30px' }"
      src="/user/credits.png">

    <span
      class="text"
      :style="{ fontSize: (text >= 1000 && wXS) ? '18px': '21px' }"
      v-html="text" />
  </BtnDropShadow>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Add'
    }
  }
};
</script>

<style lang="stylus" scoped>
.text
  @apply font-medium 
  margin auto 21px auto 0px

.btn--credit
  @apply p-0 bg-primaryWhite flex
  min-width 84px
  height 44px
  margin-left 10px

  .img--credit
    margin 7px 5px 5px 5px

  >>> .v-btn__content
    @apply font-medium
    font-size 21px
  
  &__icon
    @apply h-3 w-3
    margin-right 7px
  
@screen sm
  .btn--credit
    @apply ml-0
    height 50px !important

    .img--credit
      margin 10px 15px 10px 20px
</style>
