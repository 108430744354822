<template>
  <button
    :ripple="false"
    :color="background"
    :class="[hover ? 'btn--hover' : '']"
    class="btn--solid no-select btn"
    :style="{ height, width }"
    :disabled="disabled"
    depressed
    @click="handleClick">
    <component
      :is="svgUpload"
      class="flex-0"
      style="margin-right: 10px; width: 16px; height: 16px; display: inline-block; margin-bottom: 0.3em" />
    <span
      :disabled="busy"
      :class="[textColor ? textColor + '--text' : 'white--text']">{{ text }}
      <slot />
    </span>
    <v-progress-circular
      v-if="busy"
      :value="100"
      indeterminate
      class="spinner"
      color="white"
      size="20"
      style="position: absolute; margin-left:0.5em " />
  </button>
</template>

<script>
import SvgUpload from '~/assets/svg-global/upload.svg';
export default {
  components: {
    SvgUpload
  },
  props: {
    text: {
      type: String,
      default: 'Upload'
    },

    textColor: {
      type: String,
      default: 'primaryBlack'
    },

    background: {
      type: String,
      default: '#eaecee'
    },

    width: {
      type: String,
      default: '100%'
    },

    height: {
      type: String,
      default: '50px'
    },

    hover: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    svgUpload: SvgUpload,
    busy: false
  }),
  methods: {
    handleClick () {
      this.busy = true;
      // Do something that takes some time
      setTimeout(() => {
        this.busy = false;
      }, 2000);
    }
  }
};
</script>

<style lang="stylus" scoped>
// .btn--solid >>> {
//   // box-shadow 0 2px 11px 0 rgba(67, 86, 100, 0.19), -1px 1px 2px 0 rgba(67, 70, 74, 0) !important
//   .v-btn__content {
//     font-size: 16px;
//     font-weight: 500;
//     text-align: center;
//     color: white;
//     text-transform: none;
//   }
// }

.btn
  @apply text-white text-base font-medium text-center normal-case
</style>
