<template>
  <button
    :ripple="false"
    outlined
    color="secondary"
    :style="{ height: height }"
    class="btn--outline no-select btn"
    :class="[hover ? 'btn--hover' : '']"
    @click="handleClick">
    <slot
      name="prepend"
      :disabled="busy" />

    {{ text }}

    <slot />

    <slot name="append" />
    <v-progress-circular
      v-if="busy"
      :value="100"
      indeterminate
      class="spinner"
      color="white"
      size="20"
      style="position: absolute; margin-left:0.5em " />
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },

    height: {
      type: String,
      default: '40px'
    },

    icon: {
      type: Object,
      default: () => {}
    },

    setIcon: {
      type: Object,
      default: () => {
        return { right: '10px', width: '16px', height: '16px', top: '0.5px' };
      }
    },

    hover: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    busy: false
  }),
  methods: {
    handleClick () {
      this.busy = true;
      // Do something that takes some time
      setTimeout(() => {
        this.busy = false;
      }, 2000);
    }
  }
};
</script>

<style lang="stylus" scoped>
// .btn--outline >>>
//   .v-btn__content
//     font-size 16px
//     font-weight 500
//     text-align center
//     color primaryBlack
//     text-transform none

.btn
  @apply text-base font-medium text-center normal-case text-primaryBlack
</style>
