export default function ({ $axios, redirect }) {
  $axios.onRequest(config => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Making request to ' + config.url);
    }
  });

  $axios.onError(error => {
    const code = parseInt(error.response && error.response.status);
    if (code === 400) {
      console.log('Authorization error');
    }
  });
}
