<template>
  <b-navbar
    style="z-index: 105;gst"
    :style="{ height: wXS ? '52px' : '80px' }">
    <template slot="brand">
      <b-navbar-item
        tag="router-link"
        :to="{ path: '/' }">
        <Logo
          :white="(!opaque && !wXS && !block) || (fixed && !wXS && !block) || dark"
          :height="32" />
      </b-navbar-item>
    </template>

    <template slot="start">
      <b-navbar-item
        v-for="(item, i) in items"
        :key="i"
        tag="router-link"
        :to="item.link"
        :v-if="mobileMenuSearch"
        class="d-flex"
        style="margin-right: 32px; align-items: center; z-index:9999">
        <HeaderMenuItem
          :item="item"
          :last="i + 1 === items.length"
          style=" pointer-events: none;" />
      </b-navbar-item>
    </template>

    <template slot="end">
      <b-dropdown
        v-if="userPresent"
        class="root-dropdown"
        :triggers="['hover']"
        aria-role="list"
        position="is-bottom-left"
        :inline="wXS || wSM ? true : false">
        <a
          slot="trigger"
          role="button"
          class="navbar-item menu-link">
          <img
            class="menu-image"
            :src="menuPng"
            alt="avatar">
          <b-icon icon="menu-down" />
        </a>

        <a href="/dashboard">
          <b-dropdown-item
            aria-role="listitem"
            class="title-dropdown">
            My Dashboard
          </b-dropdown-item>
        </a>

        <hr class="navbar-divider">
        <a
          :href="publicProfileUrl"
          target="_blank">
          <b-dropdown-item
            aria-role="listitem"
            class="title-dropdown">
            My Public Profile
          </b-dropdown-item>
        </a>

        <hr class="navbar-divider">
        <b-dropdown-item
          aria-role="menu-item"
          class="title-dropdown">
          <nuxt-link to="/user/complete-profile">
            Edit Profile
          </nuxt-link>
        </b-dropdown-item>

        <hr class="navbar-divider">
        <b-dropdown-item
          aria-role="menu-item"
          class="title-dropdown">
          <a @click="logout">Logout</a>
        </b-dropdown-item>
      </b-dropdown>

      <b-navbar-item
        v-else
        tag="div">
        <div class="buttons">
          <nuxt-link
            :to="'/login'"
            class="login-btn">
            Login
          </nuxt-link>
        </div>
      </b-navbar-item>

      <b-navbar-item v-if="!userPresent">
        <nuxt-link :to="setButton.link">
          <BtnSolid
            class="new-project-button"
            :plus="userPresent"
            :text="setButton.name"
            :width="setButton.width"
            :height="setButton.height"
            :background="setButton.background"
            :text-color="setButton.text" />
        </nuxt-link>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import { mapGetters } from 'vuex';
import SvgHeaderMenuBar from '~/assets/svg/profile/header-menu-bar.svg';
import SvgDropdown from '~/assets/svg/header/header-dropdown.svg';
import SvgSearch from '~/assets/svg/profile/search.svg';
export default {
  components: {
    SvgHeaderMenuBar,
    SvgDropdown,
    SvgSearch
  },
  props: {
    block: {
      type: Boolean,
      default: false
    },
    noShadowOnStart: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    },
    offsetYPosition: {
      type: Number,
      default: 0
    },
    mobileMenuSearch: {
      type: Boolean,
      default: false
    },
    about: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isOpen: false,
    dropdown: 'SvgDropdown',
    search: 'SvgSearch',
    notifications: 0,
    items: [
      // { title: 'Discover', link: '/', icon: 'SvgHeaderDiscover' },
      { title: 'Projects', link: '/projects', icon: 'SvgHeaderProjects' },
      // {
      //   title: 'Opportunities',
      //   link: '/opportunities',
      //   icon: 'SvgHeaderOpportunities'
      // },
      { title: 'Mentors', link: '/mentors', icon: 'SvgHeaderContent' },
      { title: 'About', link: '/about', icon: 'SvgHeaderTeam' }
    ],
    dashboard: [
      { link: '/profile', icon: 'SvgHeaderSearch', height: 16, width: 16 },
      {
        title: 'Dashboard',
        link: '/projects',
        icon: 'SvgHeaderDashboard',
        height: 14,
        width: 14
      }
    ],
    items2: [
      { title: 'About', link: '/about' },
      { title: 'Sponsors', link: '/sponsors' }
    ],
    aboutItems: [
      { link: '/about', text: 'About' },
      { link: '/contact', text: 'Contact Us' },
      { link: '/', text: 'Browse' }
    ],
    menuPng: 'https://www.baytekent.com/wp-content/uploads/2016/12/facebook-default-no-profile-pic1.jpg',
    offsetTop: 0
  }),
  computed: {
    ...mapGetters(['isAuthenticated', 'loggedInUser']),
    opaque () {
      return this.offsetYPosition <= this.offsetTop;
    },
    iconsDark () {
      return (this.opaque || this.block || this.dark) && !this.fixed;
    },
    setButton () {
      if (this.userPresent) {
        return {
          link: '/projects',
          name: 'New project',
          width: '152px',
          height: '40px',
          background: 'primary',
          text: 'white'
        };
      } else {
        return {
          link: '/signup',
          name: 'Sign up',
          width: '120px',
          height: '40px',
          background: 'primary',
          text: 'white'
        };
      }
    },
    profileButton () {
      return {
        link: '/dashboard',
        name: 'Dashboard',
        height: '40px',
        background: 'primary',
        text: 'white'
      };
    },
    userPresent () {
      return this.isAuthenticated;
    },
    publicProfileUrl () {
      return `/profile/${this.loggedInUser.id}`;
    }
  },
  mounted () {
    window.addEventListener('scroll', (e) => {
      let scrollPosition = 0;
      scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      this.offsetTop = scrollPosition;
    });
    if (this.isAuthenticated) {
      this.menuPng = this.loggedInUser.profile_picture;
    }
    // if (this.isAuthenticated) {
    //   const userProfile = await this.$axios.get('users/' + this.loggedInUser.id);
    //   this.menuPng =
    //     userProfile.data.profile_picture ||
    //     'https://www.baytekent.com/wp-content/uploads/2016/12/facebook-default-no-profile-pic1.jpg';
    // }
  },
  methods: {
    async logout () {
      await this.$auth.logout();
    }
  }
};
</script>

<style lang="stylus" scoped>
.text
  &--primaryGreyLight
    color primaryGreyLight
.login-btn
  @apply text-base font-medium leading-normal justify-center items-center w-3/6
  color primaryBlack
.menu-link
  @apply flex items-center h-full relative
.menu-image
  @apply w-10 h-10 rounded-full border-solid border-2 max-h-full
  box-shadow 0 2px 4px 0 rgba(44, 63, 88, 0.2)
  border solid 2px #ffffff
.title-dropdown
  @apply ml-6
.root-dropdown
  >>> .dropdown-content
    @media (max-width: 600px)
      @apply shadow-none

.new-project-button
  @apply ml-5
@screen sm
  .title-dropdown
    @apply ml-0
  .new-project-button
    @apply ml-0
</style>
