<template>
  <div>
    <div
      v-if="label"
      class="input--label"
      :style="{marginBottom: wXS ? '10px': '10px'}"
      v-text="label" />

    <v-text-field
      v-model="password"
      :placeholder="placeholder"
      :append-icon="visibility ? '$vuetify.icons.visibility' : '$vuetify.icons.visibility_off'"
      :color="color"
      :rules="[isRequired, minText]"
      :type="visibility ? 'text' : 'password'"
      :validate-on-blur="validateOnBlur"
      class="input--password"
      outlined
      autocomplete="new-password"
      data-lpignore="true"
      :messages="messages"
      @click:append="visibility = !visibility"
      @input="$emit('input', password)" />
  </div>
</template>

<script>
export default {
  props: {
    messages: {
      type: String,
      default: ''
    },

    required: {
      type: Boolean,
      default: true
    },

    placeholder: {
      type: String,
      default: null
    },

    validateOnBlur: {
      type: Boolean,
      default: false,
    },

    color: {
      type: String,
      default: 'primaryGrey'
    },

    label: {
      type: String,
      default: 'Password'
    }
  },

  data () {
    return {
      visibility: false,

      password: ''
    };
  },

  computed: {
    isRequired () {
      return (this.required) ? value => !!value || 'Required' : true;
    },

    minText () {
      return value => value.length >= 8 || 'Please enter a minium of 8 characters.';
    },
  },

  created () {
    this.$nuxt.$on('clear-text', () => {
      this.password = '';
    });
  },
};
</script>

<style lang="stylus" scoped>
.input--label
  font-size 12px
  color primaryGrey
  line-height 16px

.v-input.input--password
  padding 0
  margin 0
  border-radius 8px

  >>> .v-input__slot
    height 50px
    min-height auto
    margin-bottom 6px
    padding 0 16px !important

    &::before
      border-color primaryGrey

    input
      padding 0
      font-size 16px
      color primaryBlack

      // @media (max-width 600px)
      //   font-size 16px

    ::placeholder
      font-size 16px
      color primaryGrey

      // @media (max-width 600px)
      //   font-size 16px

    fieldset
      border-width 1px

  // Error text
  // &.error--text >>> .v-input__slot
    // border-style inset
    // border 0

  // Error messages
  >>> .v-text-field__details
    padding 0 !important
    margin-bottom 0 !important

    .v-messages__message
      line-height 14px
      font-size 12px
      margin-bottom 10px !important

  // appended icon
  >>> .v-input__append-inner
    margin-top 0
    height 100%
    padding-left 8px !important

    .v-input__icon
      height 100%
      min-width unset

      // icon
      .v-icon
        width 20px
        height 100%
        fill primaryBlack

  // // prepended icon
  // >>> .v-input__prepend-inner 
  //   padding-right 12px

  //   .v-input__icon
  //     height 16px
  //     width 16px
  //     min-width unset

  //     // icon
  //     .v-icon
  //       width 100%
  //       height 100%
</style>
