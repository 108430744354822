<template>
  <div
    style="display: flex; align-items: center"
    class="content-rating">
    <component
      :is="(rateStar - index == 0.5 )? 'SvgStarHalf' :((rateStar - index >= 1 ) ?'SvgStarFull':'SvgStarNone')"
      v-for="(n,index) in 5"
      :key="index"
      class="icon-star" />
  </div>
</template>

<script>
import SvgStarFull from '~/assets/svg/projects/star-full.svg';
import SvgStarNone from '~/assets/svg/projects/star-none.svg';
import SvgStarHalf from '~/assets/svg/projects/star-half.svg';

export default {
  components: {
    SvgStarFull,
    SvgStarNone,
    SvgStarHalf
  },
  props: {
    rating: {
      type: Number,
      default: 0
    }
  },
  computed: {
    rateStar () {
      return ((Math.round(this.rating * 2) / 2).toFixed(1));
    },

  },
};
</script>

<style lang="stylus" scoped>
.content-rating
  display flex
  align-items center
  max-width 120px

.icon-star
  width 22px
  height 22px
  padding-top 0.5px
  margin-right 2px

</style>