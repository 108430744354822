<template>
  <button
    :ripple="false"
    :color="background"
    :class="[hover ? 'btn--hover' : '']"
    class="btn--solid no-select btn"
    :style="{ height, width, background }"
    :disabled="disabled"
    depressed
    @click="handleClick">
    <component
      :is="svg"
      v-if="plus"
      class="flex-0"
      style="margin-right: 10px; width: 16px; height: 16px; padding-top: 0.5px; display: inline" />
    <component
      :is="icon"
      v-if="icon"
      class="flex-0"
      style="margin-right: 10px; width: 16px; height: 16px; padding-top: 0.5px;" />
    <span
      :disabled="busy"
      :class="[textColor ? textColor + '--text' : 'white--text']"
      :style="{ color: textColor }">{{ text }}
      <slot />
    </span>
    <v-progress-circular
      v-if="busy"
      :value="100"
      indeterminate
      class="spinner"
      color="white"
      size="20"
      style="position: absolute; margin-left:0.5em " />
  </button>
</template>

<script>
import SvgPlus from '~/assets/svg-global/plus-white.svg';
import SvgPlusDark from '~/assets/svg-global/plus-dark.svg';
export default {
  components: {
    SvgPlus,
    SvgPlusDark
  },
  props: {
    text: {
      type: String,
      default: 'Button'
    },

    textColor: {
      type: String,
      default: null
    },

    background: {
      type: String,
      default: 'primary'
    },

    width: {
      type: String,
      default: '100%'
    },

    height: {
      type: String,
      default: '50px'
    },

    icon: {
      type: String,
      default: null
    },

    hover: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    plus: {
      type: Boolean,
      default: false
    },

    dark: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    svgPlus: SvgPlus,
    svgDark: SvgPlusDark,
    busy: false
  }),
  computed: {
    svg () {
      return this.dark ? this.svgDark : this.svgPlus;
    }
  },
  methods: {
    handleClick () {
      this.busy = true;
      // Do something that takes some time
      setTimeout(() => {
        this.busy = false;
      }, 2000);
    }
  }
};
</script>

<style lang="stylus" scoped>
// .btn--solid >>> {
//   // box-shadow 0 2px 11px 0 rgba(67, 86, 100, 0.19), -1px 1px 2px 0 rgba(67, 70, 74, 0) !important
//   .v-btn__content {
//     font-size: 16px;
//     font-weight: 500;
//     text-align: center;
//     color: white;
//     text-transform: none;
//   }
// }

.btn
  @apply text-white text-base font-medium text-center normal-case bg-primary
</style>
