<template>
  <v-app
    dark>
    <div
      style="flex: 1 0 auto;"
      class="w-100 d-flex justify-center align-center">
      <div
        class="text-center"
        style="padding-bottom: 40px;">
        <h1 v-if="error.statusCode === 404">
          {{ pageNotFound }}
        </h1>
        <h1 v-else>
          {{ otherError }}
        </h1>
        
        <NuxtLink to="/">
          Home page
        </NuxtLink>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  layout: 'empty',

  props: {
    error: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      pageNotFound: '404 Not Found',
      otherError: 'An error occurred'
    };
  },
  head () {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
    return {
      title
    };
  }
};
</script>

<style scoped>
h1 {
  font-size: 20px;
}
</style>
