<template lang="html">
  <div class="milestones-container">
    <ul
      v-if="!wXS"
      class="tabs-header">
      <div
        v-for="(tab, index) in tabs" 
        :key="tab.title" 
        class="tab"
        :class="{'selected': (index === selectedIndex)}"
        @click="selectTab(index)">
        {{ tab.title }}
      </div>
    </ul>
    <select
      v-if="wXS"
      v-model="selectedIndex"
      class="modal-select"
      @change="selectTab(selectedIndex)">
      <option
        v-for="(tab,index) in tabs"
        :key="tab.title"
        :value="index">
        {{ tab.title }}
      </option>
    </select>
    <div style="width: 100%">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selectedIndex: 0,
      tabs: []
    };
  },
  created () {
    this.tabs = this.$children;
  },
  mounted () {
    this.selectTab(0);
  },
  methods: {
    selectTab (i) {
      this.selectedIndex = i;

      this.tabs.forEach((tab, index) => {
        tab.isActive = index === i;
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.milestones-container
  @apply flex flex-col rounded-10 overflow-hidden bg-white
  box-shadow 0 2px 11px 0 rgba(67, 86, 100, 0.19), -1px 1px 2px 0 rgba(67, 70, 74, 0)

.tabs-header
  @apply p-0 mr-15 flex flex-col
  width 140px

.tab
  @apply text-base font-medium text-center text-primaryBlack opacity-50 cursor-pointer
  margin-bottom 10px
  padding 7px 10px

.selected
  @apply opacity-100 font-bold rounded-8
  background-color #ecf0f3

.modal-select
  @apply text-sm p-1 leading-relaxed w-32 h-10 my-4 ml-4 font-bold
  -webkit-appearance menulist

  &:focus
    @apply outline-none

@screen sm
  .milestones-container
    @apply flex-row rounded-none overflow-visible
    box-shadow none

</style>
