<template>
  <!-- Make container center -->
  <v-container
    class="w-100"
    :class="[
      wXS || block
        ? 'position-relative'
        : fixed
          ? 'position-absolute'
          : 'position-fixed',
      opaque && !fixed ? 'white menu--box-shadow' : ''
    ]"
    :style="{
      flex: wXS && block ? '0 0 64px' : block ? '0 0 80px' : ''
    }"
    fluid>
    <div
      class="w-100 d-flex justify-center"
      :class="[
        wXS || block ? 'position-fixed' : '',
        dark ? 'primaryBlackDark' : wXS || block ? 'white' : 'transparent',
        about ? 'transparent' : '',
        !opaque && noShadowOnStart
          ? ''
          : wXS || block || dark
            ? 'menu--box-shadow'
            : ''
      ]">
      <v-container
        fluid
        class="my-container"
        style="padding: 0 30px !important">
        <div
          class="flex-100 d-flex align-center"
          style="position: relative;"
          :style="{
            height: wXS ? '64px' : '80px',
            justifyContent: wXS ? 'space-between' : ''
          }">
          <!-- Logo -->
          <div
            class="d-flex align-center"
            :class="[wXS ? 'w-100 h-100' : '']"
            style="left: 0; z-index: 150;flex: 0">
            <nuxt-link
              to="/"
              :style="{ padding: wXS ? '' : '' }">
              <Logo
                :white="
                  (!opaque && !wXS && !block) ||
                    (fixed && !wXS && !block) ||
                    dark
                "
                :height="32" />
            </nuxt-link>
          </div>

          <div
            v-if="!wXS"
            class="w-100 d-flex align-center justify-space-between">
            <!-- Without Sign in Menu -->
            <div class="d-flex align-center">
              <div class="menu--links">
                <div
                  v-for="(item, i) in items"
                  :key="i"
                  :v-if="mobileMenuSearch"
                  class="d-flex"
                  style="margin-right: 32px; align-items: center;">
                  <nuxt-link
                    :to="item.link"
                    :class="[
                      'menu--items',
                      'd-flex',
                      'justify-center',
                      'align-center'
                    ]"
                    :style="{ height: '24px' }">
                    <HeaderMenuItem
                      :item="item"
                      :last="i + 1 === items.length" />
                  </nuxt-link>
                </div>
              </div>
            </div>

            <!-- After Sign in -->
            <div
              v-if="mobileMenuSearch && wXS"
              class="d-flex">
              <div
                class="d-flex"
                style="margin-left: -120px">
                <div
                  v-for="(item, i) in dashboard"
                  :key="i"
                  :v-if="mobileMenuSearch"
                  class="d-flex"
                  :style="{
                    padding: '18px',
                    alignItems: 'center'
                  }">
                  <nuxt-link
                    :to="item.link"
                    :class="[
                      'menu--items',
                      'd-flex',
                      'justify-center',
                      'align-center'
                    ]"
                    :style="{ height: '24px' }">
                    <component
                      :is="item.icon"
                      :style="{
                        width: item.width,
                        height: item.height,
                        marginRight: i === 0 ? 0 : 10
                      }" />

                    <span :class="['menu--text', 'primaryGreyLight--text']">
                      {{ item.title }}
                    </span>
                  </nuxt-link>
                </div>
              </div>

              <div
                class="d-flex align-center"
                style="padding-left: 18px; padding-right: 32px;">
                <div
                  class="menu--avatar"
                  style="margin-right: 10px">
                  <img src="/user-profile/user_avatar.png">
                </div>
                <SvgHeaderPath
                  class="flex-0"
                  style="width: 10px; height: 5px" />
              </div>
            </div>

            <!-- Sign in Button -->
            <div class="d-flex nowrap">
              <div>
                <div
                  v-if="userPresent"
                  class="navbar-item has-dropdown is-hoverable"
                  style="height: 100%">
                  <component
                    :is="search" />
                </div>
              </div>

              <div>
                <div
                  v-if="userPresent"
                  class="navbar-item has-dropdown is-hoverable"
                  style="height: 100%">
                  <a class="menu-link">
                    <img
                      class="menu-image"
                      :src="menuPng"
                      alt="avatar"
                      style="margin-right: 12px">
                    <component
                      :is="dropdown" />
                    <div
                      v-if="notifications > 0"
                      class="notifs">{{ notifications }}</div>
                  </a>
                  <div class="navbar-dropdown">
                    <nuxt-link
                      class="navbar-item"
                      to="/dashboard">
                      My Dashboard
                    </nuxt-link>
                    <hr class="navbar-divider">
                    <nuxt-link
                      class="navbar-item"
                      :to="publicProfileUrl">
                      My Public Profile
                    </nuxt-link>
                    <hr class="navbar-divider">
                    <nuxt-link
                      class="navbar-item"
                      to="/user/complete-profile">
                      Edit Profile
                    </nuxt-link>
                    <hr class="navbar-divider">
                    <a
                      class="navbar-item"
                      @click="logout">Logout</a>
                  </div>
                </div>
                <div v-else>
                  <nuxt-link
                    :to="'/login'"
                    class="menu--sign-up__button">
                    Login
                  </nuxt-link>
                </div>
              </div>

              <!-- <nuxt-link
                v-if="userPresent"
                :to="profileButton.link"
                class="menu--dashboard__button">
                {{ profileButton.name }}
              </nuxt-link> -->

              <nuxt-link
                :to="setButton.link">
                <BtnSolid
                  :plus="userPresent"
                  :text="setButton.name"
                  :width="setButton.width"
                  :height="setButton.height"
                  :background="setButton.background"
                  :text-color="setButton.text" />
              </nuxt-link>
            </div>
          </div>

          <!-- Web about page -->
          <div
            v-if="!wXS && about"
            class="menu--about">
            <div
              v-for="(item, i) in aboutItems"
              :key="i"
              :style="{
                marginRight: wXS ? '50px' : '32px'
              }">
              <nuxt-link
                :to="item.link"
                :style="{ height: '24px' }">
                <span :class="['menu--about-link__text']">
                  {{ item.text }}
                </span>
              </nuxt-link>
            </div>

            <!-- Sign in button -->
            <div>
              <nuxt-link
                :to="'/login'"
                class="menu--sign-up__button">
                Login
              </nuxt-link>

              <nuxt-link
                :to="setButton.link">
                <BtnSolid
                  icon="~/assets/svg-global/plus.svg"
                  :text="setButton.name"
                  :width="setButton.width"
                  :height="setButton.height"
                  :background="setButton.background"
                  :text-color="setButton.text" />
              </nuxt-link>
            </div>
          </div>

          <!-- Mobile menu items -->
          <div
            v-if="wXS"
            class="d-flex">
            <a
              v-if="$vuetify.breakpoint.xs"
              class="d-flex align-center ml-auto"
              style="z-index: 151; padding: 8px 10px; margin-right: -10px;"
              @click.prevent="$nuxt.$emit('drawer-mobile--open')">
              <v-icon
                color="primaryBlack40"
                size="28">
                menu
              </v-icon>
            </a>
          </div>
        </div>
      </v-container>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SvgHeaderMenuBar from '~/assets/svg/profile/header-menu-bar.svg';
import SvgDropdown from '~/assets/svg/header/header-dropdown.svg';
import SvgSearch from '~/assets/svg/profile/search.svg';

export default {
  components: {
    SvgHeaderMenuBar,
    SvgDropdown,
    SvgSearch
  },
  props: {
    block: {
      type: Boolean,
      default: false
    },

    noShadowOnStart: {
      type: Boolean,
      default: false
    },

    dark: {
      type: Boolean,
      default: false
    },

    fixed: {
      type: Boolean,
      default: false
    },

    offsetYPosition: {
      type: Number,
      default: 0
    },

    mobileMenuSearch: {
      type: Boolean,
      default: false
    },

    about: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    dropdown: 'SvgDropdown',
    search: 'SvgSearch',
    notifications: 0,
    items: [
      { title: 'Discover', link: '/', icon: 'SvgHeaderDiscover' },
      { title: 'Projects', link: '/projects', icon: 'SvgHeaderProjects' },
      // {
      //   title: 'Opportunities',
      //   link: '/opportunities',
      //   icon: 'SvgHeaderOpportunities'
      // },
      { title: 'Mentors', link: '/mentors', icon: 'SvgHeaderContent' },
      { title: 'About', link: '/about', icon: 'SvgHeaderTeam' }
    ],

    dashboard: [
      { link: '/profile', icon: 'SvgHeaderSearch', height: 16, width: 16 },
      {
        title: 'Dashboard',
        link: '/projects',
        icon: 'SvgHeaderDashboard',
        height: 14,
        width: 14
      }
    ],

    items2: [
      { title: 'About', link: '/about' },
      { title: 'Sponsors', link: '/sponsors' }
    ],

    aboutItems: [
      { link: '/about', text: 'About' },
      { link: '/contact', text: 'Contact Us' },
      { link: '/', text: 'Browse' }
    ],

    menuPng: 'https://www.baytekent.com/wp-content/uploads/2016/12/facebook-default-no-profile-pic1.jpg',

    offsetTop: 0
  }),

  computed: {
    ...mapGetters(['isAuthenticated', 'loggedInUser']),
    opaque () {
      return this.offsetYPosition <= this.offsetTop;
    },

    iconsDark () {
      return (this.opaque || this.block || this.dark) && !this.fixed;
    },

    setButton () {
      if (this.userPresent) {
        return {
          link: '/projects',
          name: 'New project',
          height: '40px',
          background: 'primary',
          text: 'white'
        };
      } else {
        return {
          link: '/signup',
          name: 'Sign up',
          width: '120px',
          height: '40px',
          background: 'primary',
          text: 'white'
        };
      }
    },

    profileButton () {
      return {
        link: '/dashboard',
        name: 'Dashboard',
        height: '40px',
        background: 'primary',
        text: 'white'
      };
    },

    userPresent () {
      return this.isAuthenticated;
    },
    publicProfileUrl () {
      return `profile/${this.loggedInUser.id}`;
    }
  },

  async mounted () {
    window.addEventListener('scroll', (e) => {
      let scrollPosition = 0;
      scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

      this.offsetTop = scrollPosition;
    });

    if (this.isAuthenticated) {
      const userProfile = await this.$axios.get('users/' + this.loggedInUser.id);
      this.menuPng = userProfile.data.profile_picture || 'https://www.baytekent.com/wp-content/uploads/2016/12/facebook-default-no-profile-pic1.jpg';
    }
  },

  methods: {
    async logout () {
      await this.$auth.logout();
    }
  }
};
</script>

<style lang="stylus" scoped>
.text {
  &--primaryGreyLight {
    color: primaryGreyLight;
  }
}

.menu {
  &--search {
    display: flex;
  }

  &--links {
    margin: 0 60px 0 40px;
    display: flex;
  }

  &--items {
    font-size: 14px;
    font-weight: 500;
  }

  &--avatar {
    width: 40px;
    height: 40px;
  }

  &--about {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &--about-link__text {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: primaryWhite;
  }

  &--sign-up__button {
    font-size: 16px;
    font-weight: 500;
    color: primaryBlack;
    line-height: 1.5;
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--dashboard__button {
    font-size: 16px;
    font-weight: 500;
    color: primaryBlack;
    line-height: 1.5;
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.menu--icon-links > a {
  display: flex;

  > svg {
    margin-right: 30px;
    height: 20px;
    width: 20px;

    @media (max-width: 600px) {
      margin-right: 0;
      height: 18px !important;
      width: 18px;
    }
  }
}

.menu--box-shadow {
  box-shadow: 0 2px 11px 0 rgba(67, 86, 100, 0.19), -1px 1px 2px 0 rgba(67, 70, 74, 0);
}

.menu-link {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 30px;
  position: relative;
}

.menu-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(44, 63, 88, 0.2);
  border: solid 2px #ffffff;
  max-height: 100%;
}

.notifs {
  position: absolute;
  z-index: 1;
  width: 20px;
  height: 20px;
  background-color: #008bfc;
  top: -5px;
  left: 28px;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(44, 63, 88, 0.23);
  border: solid 2px #ffffff;
  font-size: 10px;
  color: #fff;
  text-align: center;
  line-height: 1.8;
}
</style>
