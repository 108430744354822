<template>
  <div class="d-flex align-center">
    <component
      :is="!dark ? item.icon : item.icon"
      style="height: 20px; width: 20px;"
      :style="{marginRight: 10}" />

    <span
      class="item--text"
      :class="[!dark ? 'primaryGreyLight--text' : 'primaryBlack--text']">
      {{ item.title }}
    </span>
  </div>
</template>

<script>
import SvgHeaderSearch from '~/assets/svg/profile/header-search.svg';
import SvgHeaderProjects from '~/assets/svg/profile/header-projects.svg';
import SvgHeaderMentors from '~/assets/svg/profile/header-mentors.svg';
import SvgHeaderOpportunities from '~/assets/svg/profile/header-opportunities.svg';
import SvgHeaderDiscover from '~/assets/svg/profile/header-discover.svg';
import SvgHeaderDiscoverWhite from '~/assets/svg/header/header-discover-white.svg';
import SvgHeaderDashboard from '~/assets/svg/profile/header-dashboard.svg';
import SvgHeaderPath from '~/assets/svg/profile/header-path.svg';
import SvgHeaderMenuBar from '~/assets/svg/profile/header-menu-bar.svg';
import SvgHeaderContent from '~/assets/svg/header/header-content.svg';
import SvgHeaderTeam from '~/assets/svg/header/header-team.svg';

export default {
  components: {
    SvgHeaderSearch,
    SvgHeaderProjects,
    SvgHeaderMentors,
    SvgHeaderOpportunities,
    SvgHeaderDiscover,
    SvgHeaderDashboard,
    SvgHeaderPath,
    SvgHeaderMenuBar,
    SvgHeaderDiscoverWhite,
    SvgHeaderContent,
    SvgHeaderTeam
  },

  props: {
    item: {
      type: Object,
      default: () => {}
    },

    last: {
      type: Boolean,
      default: false
    },

    dark: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    hover: false
  })
};
</script>

<style lang="stylus" scoped>
.item--text
  font-size 16px
  height 24px
  line-height 1.5
  white-space nowrap

span:hover
  color primaryBlack !important
</style>