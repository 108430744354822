
<template>
  <div 
    v-if="loading" 
    class="loading-page">
    <v-progress-circular
      :width="3"
      :size="48"
      color="primary"
      style="margin-bottom: 24px;"
      indeterminate />
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false
  }),

  methods: {
    start () {
      this.loading = true;
    },

    finish () {
      this.loading = false;
    }
  }
};
</script>

<style lang="stylus" scoped>
.loading-page 
  position fixed
  top 0
  left 0
  width 100%
  height 100vh
  z-index 1001
  background-color white
  display flex
  align-items center
  justify-content center
</style>