export const state = () => ({
  answers: []
});

export const mutations = {
  add (state, answer) {
    state.answers.push(answer);
  },
  reset (state) {
    state.answers = [];
  }
};
