<template>
  <div style="display: flex; align-items: center">
    <div class="progress--background">
      <div
        class="progress--bar"
        :style="{width: progress + '%'}" />
    </div>
    <p style="font-size: 12px">
      {{ progress }}% completed
    </p>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="stylus" scoped>
.progress--background
  @apply rounded-3 bg-primaryWhiteNone mr-4 h-1
  width 220px

.progress--bar
  @apply rounded-3 bg-primaryOrange h-full

@screen sm
  width 300px

</style>