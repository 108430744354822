<template>
  <v-checkbox
    slot="label"
    v-model="checkbox"
    class="input--checkbox"
    :rules="[isRequired]"
    hide-details
    :ripple="false"
    @click:append="$emit('input', checkbox)">
    <template slot="label">
      I agree with the&nbsp;<a
        href="/terms"
        target="_blank"
        @click.stop> Terms and Conditions </a>
    </template>
  </v-checkbox>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },

    required: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      checkbox: false,
    };
  },

  computed: {
    isRequired () {
      return (this.required) ? value => !!value || 'Required' : true;
    },
  }
};
</script>

<style lang="stylus" scoped>
.input--checkbox >>>
  padding-top 0
  margin-top 0

  .v-label
    font-size 14px
    line-height 24px
    color primaryBlack

  .v-input__slot
    min-height auto
    margin-bottom 0
</style>
