<template>
  <BtnOutline
    class="btn--add"
    :width="width"
    :height="height">
    <SvgPlus class="btn--add__icon" />

    <span v-html="text" />
  </BtnOutline>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Add'
    },

    width: {
      type: String,
      default: '100%'
    },

    height: {
      type: String,
      default: '40px'
    }
  }
};
</script>

<style lang="stylus" scoped>
.btn--add
  @apply border border-solid border-primaryGreyDim h-8 !important
  padding 0 16px 0 12px !important

  >>> .v-btn__content
    @apply font-normal leading-6 !important
    letter-spacing normal !important

  &__icon
    @apply h-3 w-3 inline
    margin-right 7px
  
</style>
