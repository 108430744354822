<template>
  <div
    class="d-flex"
    :style="{ marginLeft: left }">
    <SvgLogoWhite
      v-if="white"
      class="flex-0"
      :style="{height: wXS ? height + 'px' : height + 'px'}"
      style="width: auto;" />

    <SvgLogoBlack
      v-if="black"
      class="flex-0"
      :style="{height: wXS ? height + 'px' : height + 'px'}"
      style="width: auto;" />

    <SvgLogoColor
      v-if="color"
      class="flex-0"
      :style="{ height: wXS ? height + 'px' : height + 'px' }"
      style="width: auto;" />
  </div>
</template>

<script>
import SvgLogoColor from "~/assets/svg/header/logo-color.svg";
import SvgLogoWhite from "~/assets/svg/header/logo-white.svg";
import SvgLogoBlack from "~/assets/svg/header/logo-black.svg";

export default {
  components: {
    SvgLogoColor,
    SvgLogoWhite,
    SvgLogoBlack
  },

  props: {
    height: {
      type: Number,
      default: 38,
    },

    left: {
      type: String,
      default: ''
    },

    white: {
      type: Boolean,
      default: false
    },

    black: {
      type: Boolean,
      default: false
    },

    color: {
      type: Boolean,
      default: true
    },

    brandNew: {
      type: Boolean,
      default: false
    }
  },
};
</script>