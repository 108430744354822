import Vue from 'vue';
import { StripeCheckout } from 'vue-stripe-checkout';

const options = {
  // key: process.env.STRIPE_PUBLIC_KEY,
  key: 'pk_test_51H1oDSFqg3iKAuAME6YbQ24KLAPB2lFqivPeCZiuz1gbzHyP79FNnn94D37un3f0CuwM473x2J33lDTzntYgvc1y00ApK8BzSY',
  locale: 'auto',
  currency: 'USD',
  billingAddress: true,
  panelLabel: 'Pay {{amount}}'
};

Vue.use(StripeCheckout, options);
// Vue.component(StripeCheckout);
