<template>
  <div>
    <div
      v-if="label"
      class="input--label"
      :style="{marginBottom: wXS ? '10px': '10px'}"
      v-text="label" />

    <v-select
      v-model="select"
      :placeholder="placeholder"
      :rules="[isRequired]"
      :items="items"
      disable-lookup
      type="text"
      data-lpignore="true"
      autocomplete="new-password"
      outlined
      validate-on-blur
      no-data-text=""
      append-icon="arrow_drop_down"
      class="form__select"
      @input="$emit('input', select)" />
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true
    },

    placeholder: {
      type: String,
      default: null
    },

    required: {
      type: Boolean,
      default: true
    },

    label: {
      type: String,
      default: null
    },

    initialValue: {
      type: String,
      default: null
    },
  },
  data () {
    return {
      select: '',
    };
  },

  computed: {
    isRequired () {
      return (this.required) ? value => !!value || 'Required' : true;
    }
  },

  created () {
    if (this.initialValue) {
      this.text = this.initialValue;
    }

    this.$nuxt.$on('clear-text', () => {
      this.select = '';
    });
  },
};
</script>

<style lang="stylus" scoped>
.input--label
  font-size 12px
  color primaryGrey
  line-height 16px

.form__select.v-input
  padding 0
  margin 0
  border-radius 8px !important

  >>> .v-input__slot
    cursor pointer
    height 50px
    min-height auto
    padding 0 16px !important
    margin-bottom 6px !important
    &::before
      border-color primaryGrey

    .v-select__selection
      cursor pointer !important
      width 100%
      max-width unset

    .v-input__append-inner
      margin-top 0
      height 100%
      display flex
      align-items center

  >>>  .v-text-field__details
      margin-bottom 0 !important

  // select list
  >>> .v-select__slot
    cursor pointer
    height 50px
    min-height auto
    padding 0 !important
    &::before
      border-color primaryGrey

    .v-select__selections
      cursor pointer
      .v-select__selection
        margin 0
        text-transform capitalize

.v-select-list >>>
  padding 0 8px
  .v-list-item
    border-radius 8px
    padding 0 12px
    color primaryBlack !important
    &::before
      border-radius 8px
    &:hover
      background rgba(primaryBlack, 5%)
</style>
