import firebase from 'firebase/app';
import 'firebase/auth';
import firebaseClientKey from '~~/config/firebaseClientKey';

export default (ctx, inject) => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseClientKey);
  }

  inject('firebase', firebase);
};
