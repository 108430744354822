<template>
  <div
    :class="[badgeName, 'd-flex']"
    style="align-items: center; justify-content: space-evenly; width: auto;">
    <div
      :class="badgeText"
      style="text-align: center;"
      :style="textStyle">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Button'
    },

    badgeName: {
      type: String,
      default: 'Button'
    },

    badgeText: {
      type: String,
      default: 'Button'
    },

    textStyle: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="stylus" scoped>
.badge
  &--discover-featured
    width 64px
    height 16px
    border-radius 10.5px
    background-color primaryWhite

  &--discover-featured__text
    object-fit contain
    font-size 9px
    font-weight bold
    font-stretch normal
    font-style normal
    line-height 1.78
    letter-spacing normal
    color primaryBlack

  &--purple
    width 115px
    height 24px
    border-radius 12.5px
    background-color primaryPurple

  &--primaryblack
    border-radius 20px
    background-color primaryBlack

  &--red-h24
    width 80px
    height 24px
    border-radius 12.5px
    background-color primaryRed

  &--dark-red-h24
    height 24px
    border-radius 12.5px
    background-color primaryRedDark

  &--light-blue-h24
    height 24px
    border-radius 12.5px
    background-color primaryBlueLight

  &--yellow-h24
    height 24px
    border-radius 12.5px
    background-color primaryYellow

  &--dark-yellow-h24
    height 24px
    border-radius 12.5px
    background-color primaryYellowDark

  &--green-h24
    height 24px
    border-radius 12.5px
    background-color primaryGreen

  &--blue-h24
    height 24px
    border-radius 12.5px
    background-color primaryBlue

  &--white-19
    width 79px
    height 19px
    border-radius 12.6px
    border solid 1px primaryGreyLight

  &--primarywhite
    border-radius 20px
    border solid 1px primaryBlack

  &--primaryGreyDim-border
    border-radius 12px
    border solid 1px primaryGreyDim

  &--primaryLightGreen
    height 24px
    border-radius 12.5px
    background-color primaryLightGreen

  &--discover-white__text
    height 24px
    object-fit contain
    font-size 12px
    font-weight 500
    font-stretch normal
    font-style normal
    line-height 2
    letter-spacing normal
    text-align center
    color primaryWhite

  &--white-12__text
    font-size 12px
    font-weight 500
    line-height 1.67
    color primaryWhite

  &--black__text
    font-size 12px
    font-weight 500
    line-height 1.67
    color primaryBlack

  &--primaryBlack-10-bold__text
    width 55px
    opacity 1
    font-size 10.8px
    font-weight bold
    line-height 1.78
    color primaryBlack
</style>
