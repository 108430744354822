<template>
  <v-footer class="footer--container">
    <v-container fluid>
      <v-container style="max-width: 100%">
        <div class="footer--items">
          <div class="footer--logo">
            <nuxt-link
              to="/">
              <Logo
                :height="32" />
            </nuxt-link>
          </div>

          <div class="footer--links">
            <div class="footer--contacts">
              <a
                href="mailto:hello@cmpaz.com"
                :style="{
                  marginRight: '32px' }">
                Contact Us
              </a>
              <nuxt-link
                v-for="(item, i) in footer.contacts"
                :key="i"
                :to="item.link"
                :style="{
                  marginRight: i === 2 ? '50px' : '32px' }">
                <div
                  :style="{ height: '24px' }">
                  <span>
                    {{ item.text }}
                  </span>
                </div>
              </nuxt-link>
            </div>
            <div class="footer--media">
              <div
                v-for="(item, i) in footer.media"
                :key="(i + 1) * 10">
                <div
                  :style="{ height: '24px' }"
                  @click.prevent="goTo(item.link)">
                  <component
                    :is="item.image"
                    :style="{
                      width: item.width,
                      height: item.height,
                      marginRight: item.right}" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </v-container>
  </v-footer>
</template>

<script>
import SvgFooterFacebook from "~/assets/svg/index/Facebook.svg";
import SvgFooterTwitter from "~/assets/svg/index/Twitter.svg";
import SvgFooterYouTube from "~/assets/svg/index/YouTube.svg";
import SvgFooterInstagram from "~/assets/svg/index/Instagram.svg";

export default {
  components: {
    SvgFooterFacebook,
    SvgFooterTwitter,
    SvgFooterYouTube,
    SvgFooterInstagram
  },

  data: () => ({
    footer: {
      contacts: [
        // { link: '', text: 'Contact Us' },
        { link: '/terms', text: 'Terms & Conditions' },
        { link: '/faq', text: 'FAQs' }
      ],

      media: [
        {
          link: 'https://www.facebook.com/Cmpaz-105139531155146/',
          image: SvgFooterFacebook,
          width: '9px',
          height: '16px',
          right: '32px'
        },
        {
          link: '/',
          image: SvgFooterTwitter,
          width: '16px',
          height: '13px',
          right: '32px'
        },
        {
          link: '/',
          image: SvgFooterYouTube,
          width: '17px',
          height: '12px',
          right: '32px'
        },
        {
          link: '/',
          image: SvgFooterInstagram,
          width: '14px',
          height: '14px',
          right: ''
        }
      ]
    }
  }),

  methods: {
    goTo (link) {
      location.replace(link);
    }
  }
};
</script>

<style lang="stylus">
.footer
  &--logo
    @apply mb-6

  &--contacts
    @apply flex mb-8

  &--media
    @apply flex

  &--container
    @apply h-44 p-0 flex items-center
    background-color primaryWhite !important

  &--items
    @apply block

  &--links
   @apply block p-0

@screen sm
  .footer
    &--logo
      @apply mb-0
      
    &--contacts
      @apply mb-0

    &--container
      @apply h-20

    &--items
      @apply flex justify-between
    
    &--links
      @apply flex p-1

</style>
