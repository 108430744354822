<template lang="html">
  <div>
    <ul class="tabs-header">
      <div
        v-for="(tab, index) in tabs" 
        :key="tab.title" 
        class="tab"
        :class="{'selected': (index === selectedIndex)}"
        :style="{marginLeft: index === 0 ? '0px' : ''}"
        @click="selectTab(index)">
        {{ tab.title }}
      </div>
    </ul>
    <slot />
  </div>
</template>

<script>
export default {
  data () {
    return {
      selectedIndex: 0,
      tabs: []
    };
  },
  created () {
    this.tabs = this.$children;
  },
  mounted () {
    this.selectTab(0);
  },
  methods: {
    selectTab (i) {
      this.selectedIndex = i;

      this.tabs.forEach((tab, index) => {
        tab.isActive = index === i;
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.tabs-header
  width 100%
  padding 0
  border-bottom solid 1px rgba(44, 63, 88, 0.2)
  margin-bottom 40px
  display flex
  flex-direction row

  @media (max-width: 600px)
    border-bottom solid 1px transparent
    justify-content center


.tab
  margin 0 14px
  padding-bottom 12px
  font-size 20px
  line-height 1.2
  color #2c3f58
  opacity 0.5
  cursor pointer


.selected
  opacity 1
  font-weight bold
  border-bottom solid 2px #2c3f58

</style>