<template>
  <v-btn
    class="badge--solid no-select"
    :ripple="false"
    :color="backgroundColor"
    :class="[hover ? 'btn--hover' : '']"
    :style="{ height: wXS ? '40px' : '40px',
              width: width ? width : '' }"
    :disabled="disabled"
    depressed>
    <!-- <component
      :is="icon"
      v-if="icon"
      class="flex-0"
      style="margin-right: 10px; width: 16px; height: 16px; padding-top: 0.5px;" /> -->
    <span
      :class="[textColor ? textColor + '--text' : 'white--text']"
      style="cursor:pointer"
      @click="$emit('clickEdit')">
      {{ text }}
      <slot />
    </span>
    <component
      :is="icon"
      v-if="icon"
      class="flex-0"
      style="margin-left: 10px; width: 14px; height: 14px; padding-top: 0.5px;cursor:pointer"
      @click="$emit('clickRemove')" />
  </v-btn>
</template>

<script>
import SvgClose from '~/assets/svg-global/close.svg';

export default {
  components: {
    SvgClose
  },
  props: {
    text: {
      type: String,
      default: 'Button'
    },

    textColor: {
      type: String,
      default: null
    },

    backgroundColor: {
      type: String,
      default: 'badgeBlue'
    },

    icon: {
      type: String,
      default: 'SvgClose'
    },

    hover: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    width: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="stylus" scoped>
.badge--solid {
  border-radius: 20px !important;
  padding: 0 28px !important;
  cursor: default;

  &::before, &::after {
    display: none;
  }

  >>> .v-btn__content {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    text-align: center;
    color: white;
    text-transform: none;
  }
}
</style>
