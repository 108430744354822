export default ctx => {
  if (process.env.NODE_ENV !== 'production') console.log('[Middleware] Check Auth');

  const token = ctx.app.$apolloHelpers.getToken();

  if (!token) {
    ctx.error({ errorCode: 400, message: 'Please authenticate first.' });
    ctx.redirect('/');
  }

  if (token && process.env.NODE_ENV !== 'production') console.log('User authenticated.');
};
