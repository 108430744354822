<template>
  <button
    :ripple="false"
    outlined
    color="secondary"
    style="box-shadow: 0 2px 5px 0 rgba(67, 86, 100, 0.12) !important; border: none !important"
    :style="{ height: '40px', minWidth: '88px' }"
    class="btn--outline no-select btn"
    :class="[hover ? 'btn--hover' : '']">
    {{ text }}
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },

    hover: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="stylus" scoped>
// .btn--outline >>> {
//   .v-btn__content {
//     font-size: 16px;
//     font-weight: 500;
//     text-align: center;
//     color: primaryBlack;
//     text-transform: none;
//   }
// }

.btn
  @apply text-base font-medium text-center normal-case text-primaryBlack
</style>
