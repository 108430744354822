<template>
  <div>
    <div
      v-if="label && !typeLabel"
      class="input--label"
      :style="{marginBottom: wXS ? '10px': '10px' }"
      v-text="label" />

    <div
      v-else
      class="input--label-application"
      :style="{marginBottom: wXS ? '10px': '10px', fontStyle: 'bold' }"
      v-text="label" />

    <v-text-field
      v-model="text"
      :placeholder="placeholder"
      :readonly="readonly"
      :color="color"
      :prepend-inner-icon="prependIcon"
      :append-icon="appendIcon"
      :rules="[isRequired, isEmail, isPhone]"
      :type="inputType"
      :validate-on-blur="validateOnBlur"
      class="input--text"
      outlined
      autocomplete="new-password"
      data-lpignore="true"
      @keypress="number ? isNumber($event) : phone ? isPhoneNumber($event) : null"
      @input="$emit('input', phone ? removeSpaces(text) : text )" />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber
} from 'libphonenumber-js/mobile';

export default {
  props: {
    required: {
      type: Boolean,
      default: true
    },

    prependIcon: {
      type: String,
      default: null
    },

    placeholder: {
      type: String,
      default: null
    },

    appendIcon: {
      type: String,
      default: null
    },

    readonly: {
      type: Boolean,
      default: false
    },

    number: {
      type: Boolean,
      default: false
    },

    tel: {
      type: Boolean,
      default: false
    },

    date: {
      type: String,
      default: null
    },

    time: {
      type: Boolean,
      default: false
    },

    timeData: {
      type: String,
      default: null
    },

    phone: {
      type: Boolean,
      default: false
    },

    email: {
      type: Boolean,
      default: false
    },

    validateOnBlur: {
      type: Boolean,
      default: false
    },

    color: {
      type: String,
      default: 'primaryGrey'
    },

    label: {
      type: String,
      default: null
    },

    typeLabel: {
      type: Boolean,
      default: false
    },

    initialValue: {
      type: String,
      default: null
    },

    text: {
      type: String,
      default: null
    },
  },

  // data: () => ({
  //   text: ''
  // }),

  computed: {
    isRequired () {
      return this.required ? (value) => !!value || 'Required' : true;
    },

    isEmail () {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return this.email
        ? (r) => pattern.test(r) || 'Invalid email format.'
        : true;
    },

    isPhone () {
      if (this.phone && this.text) {
        const phoneNumberIntl = parsePhoneNumberFromString(this.text);
        const phoneNumberSG = parsePhoneNumberFromString(this.text, 'SG');

        const isValid = phoneNumberIntl
          ? phoneNumberIntl.isValid()
          : phoneNumberSG
          ? phoneNumberSG.isValid()
          : undefined;

        return isValid || 'Invalid phone format.';
      }

      return true;
    },

    inputType () {
      if (this.number) {
        return 'number';
      } else if (this.email) {
        return 'email';
      } else if (this.tel) {
        return 'tel';
      } else {
        return 'text';
      }
    },

    dateData () {
      return this.date;
    }
  },

  watch: {
    text () {
      if (this.phone && this.text) {
        if (!this.text.includes('+')) {
          this.text = '+65 ' + this.text;
        }
      }
    }
  },

  mounted () {
    this.$nuxt.$on('form-area-reset', () => {
      if (this.timeData !== null) {
        this.text = this.timeData;
      }

      if (this.date) {
        this.text = this.dateData;
      }
    });
  },

  created () {
    if (this.initialValue) {
      this.text = this.initialValue;
    }

    this.$nuxt.$on('clear-text', () => {
      this.text = '';
    });
  },

  methods: {
    isNumber (evt) {
      const event = evt || window.event;

      const charCode = event.which ? event.which : event.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return event.preventDefault();
      }

      return event;
    },

    isPhoneNumber (evt) {
      const event = evt || window.event;

      const charCode = event.which ? event.which : event.keyCode;

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 43
      ) {
        return event.preventDefault();
      }

      return event;
    },

    removeSpaces (evt) {
      if (evt && evt.length > 0) {
        return evt.replace(/ /g, '');
      }
      return evt;
    }
  }
};
</script>

<style lang="stylus" scoped>
.input--label {
  font-size: 12px;
  color: primaryGrey;
  line-height: 16px;
}

.input--label-application {
  font-size: 16px;
  font-weight bold
  color: primaryBlack;
  line-height: 16px;
}

.v-input.input--text {
  padding: 0;
  margin: 0;
  border-radius: 8px;

  >>> .v-input__slot {
    height: 50px;
    min-height: auto;
    margin-bottom: 6px;
    padding: 0 16px !important;

    &::before {
      border-color: primaryGrey;
    }

    .v-input__append-inner {
      margin: 0;
      padding-left: 8px;
      height: 100%;
      display: flex;
      align-items: center;

      .v-icon {
        color: #abb2bc;
      }
    }

    input {
      padding: 0;
      font-size: 16px;
      color: primaryBlack;
    }

    ::placeholder {
      font-size: 16px;
      color: primaryGrey;
      opacity: 0.5;
    }

    fieldset {
      border-width: 1px;
    }
  }
}

// Error messages
>>> .v-text-field__details {
  padding: 0 !important;
  margin-bottom: 0 !important;

  .v-messages__message {
    line-height: 14px;
    font-size: 12px;
    margin-bottom: 10px !important;
  }
}

// input details
>>> ::-webkit-datetime-edit-ampm-field {
  display: none !important;
}

>>> input[type=time] {
  &::-webkit-clear-button {
    // display none
    appearance: none;
    margin: 0;
  }

  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
    appearance: none;
    opacity: 0;
    padding: 10;
  }
}
</style>
