<template>
  <button
    :ripple="false"
    outlined
    color="secondary"
    :style="{ height, width, minWidth: '88px', fontSize: size }"
    class="btn--outline no-select btn"
    :class="[hover ? 'btn--hover' : '']"
    @click="handleClick">
    <component
      :is="svgLike"
      v-if="like"
      :disabled="busy"
      class="flex-0"
      style="margin-right: 10px; width: 16px; height: 16px; padding-top: 0.5px; display: inline" />
    {{ text }}
    <slot />
    <v-progress-circular
      v-if="busy"
      :value="100"
      indeterminate
      class="spinner"
      color="white"
      size="20"
      style="position: absolute; margin-left:0.5em " />
  </button>
</template>

<script>
import SvgLike from '~/assets/svg/projects/thumbsup.svg';
export default {
  props: {
    text: {
      type: String,
      default: ''
    },

    hover: {
      type: Boolean,
      default: false
    },

    width: {
      type: String,
      default: '100%'
    },

    height: {
      type: String,
      default: '40px'
    },

    like: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    svgLike: SvgLike,
    busy: false
  }),
  computed: {
    size () {
      return this.like ? '12px' : '16px';
    }
  },
  methods: {
    handleClick () {
      this.busy = true;
      // Do something that takes some time
      setTimeout(() => {
        this.busy = false;
      }, 2000);
    }
  }
};
</script>

<style lang="stylus" scoped>
// .btn--outline >>> {
//   .v-btn__content {
//     // font-size: 16px;
//     font-weight: 500;
//     text-align: center;
//     color: primaryBlack;
//     text-transform: none;
//   }
// }
.btn
  @apply text-base font-medium text-center normal-case text-primaryBlack
</style>
