export const state = () => ({
  language: 'en'
});

export const getters = {
  rWH () {
    return window.innerHeight + 'px';
  },

  isAuthenticated (state) {
    return state.auth.loggedIn;
  },

  loggedInUser (state) {
    return state.auth.user;
  }
};
