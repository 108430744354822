<template>
  <div>
    <Icon />
  </div>
</template>

<script>
import Icon from '~/assets/icons/login/visibility.svg';

export default {
  components: {
    Icon
  }
};
</script>
