<template>
  <v-textarea
    v-model="text"
    :placeholder="placeholder"
    :color="color"
    :rules="[isRequired]"
    :rows="wXS ? 5 : rows"
    :counter="counter"
    autocomplete="new-password"
    data-lpignore="true"
    type="text"
    outlined
    class="form__textarea"
    style="user-select: none;"
    @input="$emit('input', text)" />
</template>

<script>
export default {
  props: {
    required: {
      type: Boolean,
      default: true,
    },

    placeholder: {
      type: String,
      default: null
    },

    color: {
      type: String,
      default: 'primaryBlack20'
    },

    counter: {
      type: Boolean,
      default: false
    },

    rows: {
      type: Number,
      default: 5
    },

    value: {
      type: String,
      default: null
    },
  },

  data () {
    return {
      text: '',
    };
  },

  computed: {
    isRequired () {
      return (this.required) ? value => !!value || 'Required' : true;
    }
  },

  created () {
    if (this.value) {
      this.text = this.value;
    }

    this.$nuxt.$on('clear-text', () => {
      this.text = '';
    });
  }
};
</script>

<style lang="stylus" scoped>
.form__textarea
  padding 0
  margin 0

  >>> .v-input__slot
    height auto
    min-height auto
    padding 0 16px !important
    margin-bottom 4px

    fieldset
      padding 0 15px !important
      border-color primaryBlack20 !important

    ::placeholder
      opacity 0.5

  >>> .v-text-field__details
    margin-bottom 10px
    padding 0

    .v-messages__message
      line-height 16px

    // .v-counter
    //   color #eee

  &.error--text >>>
    .v-input__slot::before
      border 0
      border-bottom 1px solid #ff9600 !important
</style>
